import { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import Loader from "components/Loader";

import ApplicationLayout from "layouts/Application";

import { TenantSelectPage } from "pages/tenantSelector/TenantSelector";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { authenticationApi } from "fetch/authentication";

import {
  getAccessToken,
  setJwtData,
  clearJwtData,
  isTokenExpired,
} from "utils/jwt";

import { setupI18n } from "i18n";

const getCodeFromUrl = () => {
  const searchString = document.location.search;
  const pairs = searchString.substring(1).split("&");

  if (pairs.length) {
    return pairs.find((param) => param.includes("code="))?.replace("code=", "");
  }

  return "";
};

const LoaderScreen = () => (
  <Box
    style={{
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Loader />
  </Box>
);

enum AuthState {
  Loading = "loading",
  Authenticated = "authenticated",
  Unauthenticated = "unauthenticated",
}

export const Root = () => {
  const [authState, setAuthState] = useState<
    AuthState.Loading | AuthState.Authenticated | AuthState.Unauthenticated
  >(AuthState.Loading);
  const navigate = useNavigate();
  const storage = useRef(useUserPreferencesStore.getState());

  const getLanguageToUse = () => {
    const i18nCache = localStorage.getItem("i18nextLng");
    const userLocale = storage.current.user?.locale;
    const preferenceLanguage = storage.current.language;

    if (storage.current.isFirstLogin) {
      return [userLocale, preferenceLanguage, i18nCache, "en-GB"].find(
        (lang) => !!lang,
      );
    }

    return [i18nCache, userLocale, preferenceLanguage, "en-GB"].find(
      (lang) => !!lang,
    );
  };

  const handleAuthenticationError = () => {
    clearJwtData();
    setAuthState(AuthState.Unauthenticated);
  };

  const handleExistingToken = useCallback(async (accessToken?: string) => {
    if (isTokenExpired(accessToken)) {
      handleAuthenticationError();
    } else {
      setAuthState(AuthState.Authenticated);
    }
  }, []);

  const handleAuthorizationCode = useCallback(
    async (code: string) => {
      try {
        const response = await authenticationApi.authorize(code);
        setJwtData(response.data);
        setAuthState(AuthState.Authenticated);
        navigate("/");
      } catch (error) {
        console.error("Code exchange failed:", error);
        handleAuthenticationError();
      }
    },
    [navigate],
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getAccessToken();

        if (accessToken) {
          await handleExistingToken(accessToken);
          return;
        }

        const code = getCodeFromUrl();

        if (code) {
          await handleAuthorizationCode(code);
          return;
        }

        setAuthState(AuthState.Unauthenticated);
      } catch (error) {
        console.error("Error during authentication initialization:", error);
        handleAuthenticationError();
      }
    };

    initialize();
  }, [handleAuthorizationCode, handleExistingToken]);

  useEffect(() => {
    storage.current = useUserPreferencesStore.getState();
  }, []);

  useEffect(() => {
    const langToUse = getLanguageToUse();
    setupI18n(langToUse ?? "");
  }, [storage.current.user]);

  if (authState === AuthState.Loading) {
    return <LoaderScreen />;
  }

  if (authState === AuthState.Unauthenticated) {
    authenticationApi.authenticateClient();
    return <LoaderScreen />;
  }

  if (!storage.current.tenant) {
    return <TenantSelectPage />;
  }

  return <ApplicationLayout />;
};
